import 'flowbite/dist/flowbite.css';
import './styles/index.css';

import React, { lazy, Suspense } from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';

import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://4216e770bca8bae551b7c2957b748a0e@o4508038292373504.ingest.de.sentry.io/4508038874333264',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration()
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/api\.getignite\.app/],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

const LoadingScreen = lazy(() => import('@/pages/common/LoadingScreen'));

root.render(
  <Suspense fallback={<LoadingScreen />}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
);
