import { getUserToken } from '../firebase';
import { generateUrl } from '@/utils/generateUrl';
import { ResponseBody } from '../types/ResponseBody';
import { getIpAddress } from '../getIpAddress';
import { getAppVersion } from '@/utils/getAppVersion';
import { getBuildVersion } from '@/utils/getBuildVersion';
import { jwtDecode } from 'jwt-decode';
import { TDecodedTokenPayload } from '@/context/types/Payload';
import { generateSHA256Hash } from '@/utils/generateSHA256Hash';

const NO_CONTENT = 204;
class BaseApi {
  private baseUrl: string;
  private ipAddress: string | null = null;

  constructor() {
    this.baseUrl = process.env.API_URL ?? '';
  }

  public async getHeaders() {
    if (!this.ipAddress) {
      const { ip } = await getIpAddress();
      this.ipAddress = ip ?? '';
    }

    const userToken = await getUserToken();
    return {
      'Content-Type': 'application/json',
      'Device-OS': 'web',
      'App-Build': getBuildVersion(),
      'App-Version': getAppVersion(),
      'Device-Id': this.ipAddress,
      ...(userToken
        ? {
            Authorization: `Bearer ${userToken}`,
            Signature: this.generateHash(userToken)
          }
        : {})
    };
  }

  public async getMultiPartHeaders() {
    if (!this.ipAddress) {
      const { ip } = await getIpAddress();
      this.ipAddress = ip;
    }

    const userToken = await getUserToken();
    return {
      'Device-OS': 'web',
      'App-Build': getBuildVersion(),
      'App-Version': getAppVersion(),
      'Device-Id': this.ipAddress,
      ...(userToken
        ? {
            Authorization: `Bearer ${userToken}`,
            Signature: this.generateHash(userToken)
          }
        : {})
    };
  }

  private generateHash(userToken: string) {
    const decoded: TDecodedTokenPayload & { claims: Record<string, string> } = jwtDecode(userToken);
    const ackTime = decoded.ackTime ?? decoded.claims.ackTime;

    return generateSHA256Hash(
      JSON.stringify({
        ackTime,
        releaseHash: getAppVersion()
      })
    );
  }

  private async handleRequest<T>(path: string, options: RequestInit): Promise<ResponseBody<T>> {
    const url = generateUrl(path, this.baseUrl);
    return fetch(url, options)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject(response);
        }

        if (response.status === NO_CONTENT && response.ok) {
          return { success: true };
        }

        return response.json();
      })
      .then((response) => {
        return response;
      })
      .catch((response) => {
        if (typeof response.json === 'function') {
          throw response.json().then((json: ResponseBody<string>) => json);
        }

        throw response;
      });
  }

  async post<TResponse, TRequestBody>(path: string, body: TRequestBody | null) {
    const options: RequestInit = {
      method: 'POST',
      headers: await this.getHeaders(),
      body: body ? JSON.stringify(body) : undefined
    };

    return this.handleRequest<TResponse>(path, options);
  }

  async multiPartPost<TResponse>(path: string, formData: FormData) {
    const options: RequestInit = {
      method: 'POST',
      headers: await this.getMultiPartHeaders(),
      body: formData
    };

    return this.handleRequest<TResponse>(path, options);
  }

  async get<TResponse>(path: string) {
    const options: RequestInit = {
      method: 'GET',
      headers: await this.getHeaders()
    };

    const res = await this.handleRequest<TResponse>(path, options);
    return res;
  }

  async put<TResponse, TRequestBody>(path: string, body: TRequestBody) {
    const options: RequestInit = {
      method: 'PUT',
      headers: await this.getHeaders(),
      body: JSON.stringify(body)
    };

    return this.handleRequest<TResponse>(path, options);
  }

  async patch<TResponse, TRequestBody>(path: string, body: TRequestBody) {
    const options: RequestInit = {
      method: 'PATCH',
      headers: await this.getHeaders(),
      body: JSON.stringify(body)
    };

    return this.handleRequest<TResponse>(path, options);
  }

  async delete<TResponse, TRequestBody>(path: string, body?: TRequestBody) {
    const options: RequestInit = {
      method: 'DELETE',
      headers: await this.getHeaders(),
      body: body ? JSON.stringify(body) : undefined
    };

    return this.handleRequest<TResponse>(path, options);
  }
}

export default new BaseApi();
