import { logAnalyticsEvent } from '../firebase';
import { ErrorResponseBody } from '../types/ResponseBody';

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const handleApiError = async (responseError: any) => {
  if (typeof responseError?.then === 'function') {
    throw await responseError.then((errResponse: ErrorResponseBody) => {
      logAnalyticsEvent('exception', {
        description: `Status ${errResponse.status} with code ${errResponse.code}: ${errResponse.message}`,
        fatal: false
      });
      return errResponse;
    });
  }

  logAnalyticsEvent('exception', {
    description: responseError,
    fatal: false
  });
  throw responseError;
};
